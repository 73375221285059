import React from "react";
import { graphql, useStaticQuery } from "gatsby";
import get from "lodash/get";
import CardsBuilder from "../common/cardsBuilder";
import { useTranslation } from "react-i18next";

const VacancyCards = () => {
    const data = useStaticQuery(graphql`
        query {
            jobImages: allFile(
                filter: {
                    extension: { regex: "/(svg)/" }
                    absolutePath: { regex: "/vacancies/jobs/" }
                }
                sort: { fields: name }
            ) {
                edges {
                    node {
                        name
                        publicURL
                    }
                }
            }
        }
    `);

    let imagesData = data.jobImages.edges.map(item => get(item, "node"));

    const { t } = useTranslation();

    const textData = [];
    const jobs = [
        {title:"sales",                disabled: false},
        {title:"qa",                 disabled: true},
        {title:"mobile-development", disabled: true},
        {title:"backend-developer",  disabled: true},
        {title:"web-developer",      disabled: true},
        {title:"project-manager",    disabled: true},
    ];

    for (let i = 0; i < 6; i++) {
        textData.push({
            id: i,
            title: t(`vacancies-page.jobs.${jobs[i].title}.title`),
            description: t(`vacancies-page.jobs.${jobs[i].title}.description`),
            linkTo: `vacancy/${jobs[i].title}`,
            disabled: jobs[i].disabled,
        });
    }

    return (
        <div className="vacancies" id="vacancies">
            <CardsBuilder
                className={"vacancy"}
                data={textData}
                image={imagesData}
            />
        </div>
    );
};

export default VacancyCards;
