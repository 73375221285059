import React from "react";
import { Link } from "gatsby";

import Layout from "../components/layout";
import TopImage from "../components/vacancies/topImage";
import VacancyCards from "../components/vacancies/vacancyCards";
import BenefitsCards from "../components/vacancies/benefitsCards";
import { useTranslation } from "react-i18next";

const VacanciesPage = () => {
    const { t } = useTranslation();
    return (
        <Layout>
            <div className="vacancies-wrapper">
                <TopImage />
                <div
                    className="container"
                    style={{ display: "flex", flexDirection: "column" }}
                >
                    <div className="vacancies-page-title intro-title">
                        {t(`vacancies-page.title`)}
                    </div>
                    <div className="vacancies-page-description">
                        <Link to={"/"} style={{ color: "#005fd2" }}>
                            {t(`vacancies-page.link`)}
                        </Link>{" "}
                        {t(`vacancies-page.description`)}
                    </div>
                    <VacancyCards />
                    <div className="vacancies-page-title component-title">
                        {t(`vacancies-page.benefits.title`)}
                    </div>
                    <BenefitsCards />
                </div>
                <div className="vacancies-page-footer">
                    <p className="vacancies-page-footer-text">
                        {t(`vacancies-page.footer.description-start`)}
                        <a
                            href="mailto:job@growi.re"
                            style={{ color: "#005fd2" }}
                        >
                            job@growi.re
                        </a>
                        {t(`vacancies-page.footer.description-end`)}
                    </p>
                </div>
            </div>
        </Layout>
    );
};

export default VacanciesPage;
