import React from "react";
// import Card from "../common/card";
import { graphql, useStaticQuery } from "gatsby";
import get from "lodash/get";
import CardsBuilder from "../common/cardsBuilder";
import { useTranslation } from "react-i18next";

const BenefitsCards = () => {
	const data = useStaticQuery(graphql`
        query {
            jobImages: allFile(
                filter: {
                    extension: { regex: "/(svg)/" }
                    absolutePath: { regex: "/vacancies/benefits/" }
                }
                sort: { fields: name }
            ) {
                edges {
                    node {
                        name
                        publicURL
                    }
                }
            }
        }
    `);

	let imagesData = data.jobImages.edges.map(item => get(item, "node"));

	const { t } = useTranslation();

	const textData = [];
	const benefits = [
		"remote",
		"team",
		"company",
	];

	for (let i = 0; i < 3; i++) {
		textData.push({
			id: i,
			title: t(`vacancies-page.benefits.${benefits[i]}.title`),
			description: t(`vacancies-page.benefits.${benefits[i]}.description`),
		});
	}

	return (
		<div className="benefits" id="benefits">
			<CardsBuilder
				className={'benefit'}
				data={textData}
				image={imagesData}
				separator={true}
			/>
		</div>
	);
};

export default BenefitsCards;
